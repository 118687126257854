<template>
  <div class="wrapper">
    <head-component/>
    <section class="block-courses" v-if="posts">
      <h1>Блог</h1>
      <div class="eo-event">
        <div v-for="post in posts" :key="post.id">
          <h3>{{ post.title }}</h3>
          <p v-if="post.cover">
            <img :src="post.cover.thumbs.big">
          </p>
          <div v-html="post.text"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import HeadComponent from "../components/HeadComponent";

export default {
  name: "News",
  components: {HeadComponent},
  data() {
    return {
      posts: null
    }
  },
  mounted() {
    axios.get('posts')
    .then(res => {
      this.posts = res.data
    })
  }
}
</script>

<style scoped>
.Container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Container img {
  width: 400px;
  height: 400px;
  overflow: hidden;

}
.universarium_courses {border-collapse: collapse; width: 100%; height: 0px; border: 0px;valign: middle;}
.universarium_courses td {width: 25%; height:0px; text-align: center; border: 0;text-align: left; vertical-align: middle;;}
h3 {
  font-size: 30px;
  color: #015496;
  font-weight: 400;
  margin: 40px 0 20px 0;
  line-height: 1.5;
}
</style>